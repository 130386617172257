import React, { useState, useEffect } from "react";
import {
  contractAddress,
  abi,
  Withdrawalcontract,
  Withdrawalabi,
  loadWeb3,
} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { API } from "../../Utils/Api";
import axios from "axios";
import { toast } from "react-toastify";

const Withdrawal = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  const [userid, setuserid] = useState(LoginId);

  const [Wtype, setWtype] = useState("");
  const [NetBalance, setNetBalance] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);


  const Witdrawal_Api = async () => {
    try {
      setbtnDisable(true);
      if (LoginAddress) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(
          Withdrawalabi,
          Withdrawalcontract
        );

        let address = await loadWeb3();
        if (address.toUpperCase() != LoginAddress.toUpperCase()) {
          toast.error(`Address doesnot Macthed !`);
          setbtnDisable(false);
          return;
        }

        if (Wtype === "") {
          toast.error(`Select Withdrawal Type !`);
          setbtnDisable(false);
          return;
        }

        if (parseFloat(NetBalance) === 0) {
          toast.error(`Insufficient Withdrawal Balance!`);
          setbtnDisable(false);
          return;
        }

        // Check if the user is not registered
        // if (balanceOf >= NetBalance) {
       // toast.success(Wtype);
        let txnDataSlot = "";

        if (Wtype === "dividend") {
          txnDataSlot = await contract.methods
            .dividendWithdraw()
            .send({ from: address });
        } else if (Wtype === "infinitypoolincome") {
          txnDataSlot = await contract.methods
            .distributeInfinityPoolRewards()
            .send({ from: address });
        } else if (Wtype === "withdraw") {
          txnDataSlot = await contract.methods
            .distributePoolRewards()
            .send({ from: address });
        }

        console.log("resData", txnDataSlot);

        console.log("resData transactionHash", txnDataSlot.transactionHash);
        txnDataSlot = txnDataSlot.transactionHash;

        let responceRight = await axios.post(
          "https://adazo-api.nakshtech.info/UserWithdrawl",
          {
            uid: userid,
            address: LoginAddress,
            amount: NetBalance,
            txn: txnDataSlot,
            type: Wtype,
          }
        );

        toast.success("Withdrawal Succesfull");
        console.log("responceRight--->", responceRight);
        setbtnDisable(false);
        // } else {
        //   toast.error("Insufficiant Balance!!");
        //   setbtnDisable(false);
        // }
      } else {
        toast.error("User does not eligible for Buy Level");
        setbtnDisable(false);
      }
    } catch (error) {}
  };

  const getWithdrawalBalance = async (WithdrawalType) => {
    setWtype(WithdrawalType);
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(Withdrawalabi, Withdrawalcontract);
    const userAddress = LoginAddress;

    const resultBalance = await contract.methods.userspool(userAddress).call();
    console.log("userspool",resultBalance);

    let bal=0;
    if (WithdrawalType === "dividend") {
      bal= parseFloat(resultBalance[0])/ 10 ** 18
      setNetBalance(bal.toFixed(2));
    } else if (WithdrawalType === "infinitypoolincome") {
      bal= parseFloat(resultBalance[1])/ 10 ** 18
      setNetBalance(bal.toFixed(2));
    } else if (WithdrawalType === "withdraw") {
      bal= parseFloat(resultBalance[2])/ 10 ** 18
      setNetBalance(bal.toFixed(2));
    }else{
      setNetBalance(0);
    }
  };

  return (
    <div className="page-content">
      <div className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-muted text-black">Withdrawal Type</h5>
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <select
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      onChange={(e) => getWithdrawalBalance(e.target.value)}
                    >
                      <option>Select Withdrawl Type</option>
                      <option value="dividend">dividend</option>
                      <option value="infinitypoolincome">
                        infinitypoolincome
                      </option>
                      <option value="withdraw">withdraw</option>
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-muted text-black">Avaialble Income</h5>
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <input
                      type="text"
                      id="NetBalance"
                      className="inputes"
                      defaultValue={NetBalance}
                      name="NetBalance"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <button
                      className="copedss"
                      onClick={(e) => {
                        Witdrawal_Api();
                      }}
                      disabled={btnDisable}
                    >
                      Withdrawal
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© adazo.io.</div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Withdrawal;
