import React, { useState, useEffect } from "react";
import { contractAddress, abi, X3contract, X3abi } from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { API } from "../../Utils/Api";
import axios from "axios";

const DownlineteamReport = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  const [userid, setuserid] = useState(LoginId);

  const [DirectTeamData, setDirectTeamData] = useState([]);

  const referral_API = async () => {
    try {

      let responceRight = await axios.post("https://adazo-api.nakshtech.info/DownlineTeam", {
        uid: userid
      }
        // ,
        // {
        //   headers: {
        //     Authorization: jwtToken,
        //   },
        // }
      );

      console.log("GetIncomeReport-->", responceRight);
      responceRight = responceRight.data.data[0];
      setDirectTeamData([]);
      let arr = [];
      responceRight.forEach((item, index) => {
        arr.push({
          RowNo: item.RowNumber,
          uid: item.uid,
          userAddress: item.useraddress,
          sid: item.sponsorId,
          refAddress: item.refaddress,
          edate: item.edate1
        });
      });
      setDirectTeamData(arr);

      
    } catch (error) {

    }
  };

  useEffect(() => {
    referral_API();
  }, [userid]);



  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card partnrPdd">
                <div className="card-body">
                  <h4 className="card-title colow mb-4">Downline Team</h4>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                        <th className="align-middle" style={{ background: "navy", color: "white" }}>Sr.No.</th>
                          <th className="align-middle" style={{ background: "navy", color: "white" }}>User Id</th>
                          <th className="align-middle" style={{ background: "navy", color: "white" }}>User Address</th>
                          <th className="align-middle" style={{ background: "navy", color: "white" }}>Sponsor Id</th>
                          <th className="align-middle" style={{ background: "navy", color: "white" }}>Sponsor Address</th>
                          <th className="align-middle" style={{ background: "navy", color: "white" }}>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                      {DirectTeamData &&
                          DirectTeamData.map((item, i) => (
                            <tr  >
                              <td style={{ color: "white" }}>{item.RowNo}</td>
                              <td style={{ color: "white" }}>{item.uid}</td>
                              <td style={{ color: "white" }}>
                                {item.userAddress.slice(0, 4) + "..." + item.userAddress.slice(38, 42)}
                              </td>
                              <td style={{ color: "white" }}>{item.sid}</td>
                              <td style={{ color: "white" }}>
                                {item.refAddress.slice(0, 4) + "..." + item.refAddress.slice(38, 42)}
                              </td>
                              <td style={{ color: "white" }}>{item.edate}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* end table-responsive */}
                  {/* <div className="pagination-buttons" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <button className="colow" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Previous
                    </button>
                    <button className="colow" onClick={handleNextPage} disabled={indexOfLastRow >= data.length}>
                      Next
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© adazo.io.</div>
              {/*  <div class="col-sm-6">
                          <div class="text-sm-end d-none d-sm-block">
                              Design by adazo.io
                          </div>
                      </div> */}
            </div>
          </div>
        </footer>
        </div>
      </div>
    </div>
  );
};

export default DownlineteamReport;