import React, { useState, useEffect } from "react";
import { contractAddress, abi  , X3contract,X3abi} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";

const Income1 = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;

  let add = [];

  const contractInfo = async () => {
    try {
      window.web3 = new Web3(window.ethereum);
      let team;
      let team1;
      let userdata = [];
      let intex1;
      let formattedAddresses = [];
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let a = await contract.methods.Users(LoginAddress).call();
      
      let activeid = await contract.methods.check_useractive_id_List(LoginAddress,1).call();
      
      for(let i=0 ; i<activeid.length ; i++){
      activeid = parseInt(activeid[i])
      let aa = await contract.methods.Pool_check_Direct(parseInt(activeid)).call();
      formattedAddresses[0] = parseInt(a.firstid)
      intex1 = parseInt(aa.Id1);
      let intex2 = parseInt(aa.Id2);
      let intex3 = parseInt(aa.Id3);
      let index1address;
      let index2address;
      let index3address;
      if (intex1 > 0) {
        index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
      
        formattedAddresses[1] = parseInt(intex1)
        if (parseInt(index1address.Id1) > 0) {
          formattedAddresses[4] = parseInt(index1address.Id1)
        }
        if (parseInt(index1address.Id2) > 0) {
          formattedAddresses[5] = parseInt(index1address.Id2)
        }
        if (parseInt(index1address.Id3) > 0) {
          formattedAddresses[6] = parseInt(index1address.Id3)
        }
      }
      if (intex2 > 0) {
        index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
        formattedAddresses[2] = parseInt(intex2)
        if (parseInt(index2address.Id1) > 0) {
          formattedAddresses[7] = parseInt(index2address.Id1)
        }
        if (parseInt(index2address.Id2) > 0) {
          formattedAddresses[8] = parseInt(index2address.Id2)
        }
        if (parseInt(index2address.Id3) > 0) {
          formattedAddresses[9] = parseInt(index2address.Id3)
        }
      }
      if (intex3 > 0) {
        index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
        formattedAddresses[3] = parseInt(intex3)
        if (parseInt(index3address.Id1) > 0) {
          formattedAddresses[10] = parseInt(index3address.Id1)
        }
        if (parseInt(index3address.Id2) > 0) {
          formattedAddresses[11] = parseInt(index3address.Id2)
        }
        if (parseInt(index3address.Id3) > 0) {
          formattedAddresses[12] = parseInt(index3address.Id3)
        }
      }
    }
    let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
    let ttactiveid = await X_3contract.methods.check_useractive_id_List(LoginAddress,2).call();
    let abc = await X_3contract.methods.showuser_income(parseInt(ttactiveid[0])).call();
      console.log("123" ,abc)
      
      let z = await contract.methods.showDataContract().call();
      // const filtered = z.filter(entry => formattedAddresses.includes(parseInt(entry.userId)));  && parseInt(entry.x) === 3
      // console.log("1234" , filtered)
      const filtered = z.filter(entry => formattedAddresses.includes(parseInt(entry.userId)) );
 
      let mergedArray = filtered.concat(abc);
      setData(mergedArray); // Update state with fetched data
    } catch (error) {
      console.log("Error fetching contract data:", error);
    }
  };

//   useEffect(() => {
//     contractInfo();
//   }, [LoginAddress]);

  const formatAmount = (amount) => {
    let d = window.web3.utils.fromWei(amount.toString(), 'ether'); // Convert Wei to Ether
    if(d == 5)
      {
        d = 4.5
      }
      if(d == 10)
        {
          d = 2.25
        }      
      return d;
  };

  const formatTime = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000); // Convert BigInt to number and then to milliseconds
    return date.toLocaleString();
  };
  const formatslot = (amout) => {
    
    if(amout == 10)
      {
        amout = 2
      } 
    return amout;
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card partnrPdd">
                <div className="card-body">
                  <h4 className="card-title colow mb-4">Income1s</h4>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle">S.No</th>
                          <th className="align-middle">Amount</th>
                          <th className="align-middle">Time</th>
                          <th className="align-middle">Slot</th>
                          <th className="align-middle">Upline ID</th>
                          <th className="align-middle">Matrix ID</th>
                          <th className="align-middle">Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRows.length > 0 ? (
                          currentRows.map((item, index) => (
                            <tr key={indexOfFirstRow + index}>
                              <td>{indexOfFirstRow + index + 1}</td>
                              <td>{formatAmount(item.amount)}</td>
                              <td>{formatTime(item.time)}</td>
                              <td>{formatslot(item.slot.toString())}</td>
                              <td>{item.uplineID.toString()}</td>
                              <td>{item.userId.toString()}</td>
                              <td>{item.add}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="colow">Loading...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* end table-responsive */}
                  <div className="pagination-buttons" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <button className="colow" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Previous
                    </button>
                    <button className="colow" onClick={handleNextPage} disabled={indexOfLastRow >= data.length}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© adazo.io.</div>
              {/*  <div class="col-sm-6">
                          <div class="text-sm-end d-none d-sm-block">
                              Design by adazo.io
                          </div>
                      </div> */}
            </div>
          </div>
        </footer>
        </div>
      </div>
    </div>
  );
};

export default Income1;
