import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { loadWeb3 } from "../../connectivity/api";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { useraddress, useraddressinshort } from "../../Actions";
import {
  contractAddress,
  abi,
  tokencontractAddress,
  tokenabi,
} from "../../connectivity/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API } from "../../Utils/Api";

const Register = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("Connect to wallet");
  const [walletAddress, setWalletAddress] = useState("");
  const [uplineAddress, setUplineAddress] = useState("");
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");
  
  const [btnDisable, setbtnDisable] = useState(false);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const notify_Approve = () => toast("Approve Confirm!");
  const notify_Register = () => toast("Register Confirm!");

  const pleaseselectamount = () => toast("balance not found!");
  const pleaseselectaddress = () => toast("please select upline!");

  const alreadyregister = () => toast("This address already register!");
  const pleaseconnectwallet = () => toast("please connect wallet!");

  const connectToTheWallet = async (event) => {
    event.preventDefault();
    try {
      let address = await loadWeb3();
      console.log("Wallet Address:", address);
      dispatch(useraddress(address.toString()));
      dispatch(
        useraddressinshort(address.slice(0, 3) + "..." + address.slice(38, 42))
      );
      setWalletAddress(address.toString());
      setInputValue(address.slice(0, 5) + "..." + address.slice(37, 42));
      console.log("Updated State:", myState.toString());
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // Updated register_ function with Payment event handling
  const register_ = async () => {
    try {
      setbtnDisable(true);
      let url = new URL(window.location.href);
      let ref = url.searchParams.get("ref");
      if (!ref) {
        ref = uplineAddress;
      }

      if (walletAddress) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(abi, contractAddress);
        const tokenContract = new web3.eth.Contract(
          tokenabi,
          tokencontractAddress
        );

        // Verify contract initialization
        if (!contract || !tokenContract) {
          throw new Error("Contract or token contract initialization failed");
        }

        const user = await contract.methods.users(walletAddress).call();
        const id = user.id;
        const balanceOf = await tokenContract.methods
          .balanceOf(walletAddress)
          .call();
        const oneEtherInWei = web3.utils.toWei("22", "ether");

        if (parseInt(id) === 0) {
          // Check if the user is not registered
          if (balanceOf >= oneEtherInWei) {
            if (ref) {
              await tokenContract.methods
                .approve(contractAddress, oneEtherInWei)
                .send({ from: walletAddress });
              notify_Approve();

             let txnData= await contract.methods
                .registrationExt(ref)
                .send({ from: walletAddress });
    
              //console.log("resData",txnData);
              console.log("resData transactionHash",txnData.transactionHash);
              txnData=txnData.transactionHash;

              notify_Register();

              // Fetch past events and set up a polling mechanism for new events
              // fetchAndListenEvents(contract, web3);

              /// Registaration API call
              let userData = await contract.methods
                .users(walletAddress)
                .call();
                let uid = userData.id;
              let sponserData = await contract.methods.users(ref).call();
              let sid = sponserData.id;

              let result = await contract.methods
                .activeGoldInfos(walletAddress, 1)
                .call();
              let position = parseInt(result.position);
              let uplineId = parseInt(result.currentParent);
              let uplineAddress = await contract.methods.idToAddress(uplineId).call();

              let res = await API.post("/InsertAlllUsers", {
                uid: parseInt(uid),
                sid: parseInt(sid),
                uplineId: parseInt(uplineId),
                refAddress: ref,
                userAddress: walletAddress,
                uplineAddress: uplineAddress,
                position: position,
                package: 1,
                type: "",
                txn:txnData
              });
              console.log("Registartion API", res.data.data);
            } else {
              pleaseselectaddress();
              setbtnDisable(false);
            }
          } else {
            pleaseselectamount();
            setbtnDisable(false);
          }
        } else {
          alreadyregister();
          setbtnDisable(false);
        }
      } else {
        pleaseconnectwallet();
        setbtnDisable(false);
      }
    } catch (error) {
      console.log("Registration Error:", error);
    }
  };

  // Function to fetch past events and listen to new events
  const fetchAndListenEvents = async (contract, web3) => {
    // Fetch past events
    const pastNewUserPlaceEvents = await contract.getPastEvents(
      "NewUserPlace",
      { fromBlock: 0, toBlock: "latest" }
    );
    pastNewUserPlaceEvents.forEach((event) => handleNewUserPlaceEvent(event));

    const pastReinvestEvents = await contract.getPastEvents("Reinvest", {
      fromBlock: 0,
      toBlock: "latest",
    });
    pastReinvestEvents.forEach((event) => handleReinvestEvent(event));

    const pastPaymentEvents = await contract.getPastEvents("Payment", {
      fromBlock: 0,
      toBlock: "latest",
    });
    pastPaymentEvents.forEach((event) => handlePaymentEvent(event));

    // Set up polling mechanism for new events
    setInterval(async () => {
      const latestBlock = await web3.eth.getBlockNumber();

      const newNewUserPlaceEvents = await contract.getPastEvents(
        "NewUserPlace",
        { fromBlock: latestBlock, toBlock: "latest" }
      );
      newNewUserPlaceEvents.forEach((event) => handleNewUserPlaceEvent(event));

      const newReinvestEvents = await contract.getPastEvents("Reinvest", {
        fromBlock: latestBlock,
        toBlock: "latest",
      });
      newReinvestEvents.forEach((event) => handleReinvestEvent(event));

      const newPaymentEvents = await contract.getPastEvents("Payment", {
        fromBlock: latestBlock,
        toBlock: "latest",
      });
       newPaymentEvents.forEach((event) => handlePaymentEvent(event));
    }, 20000); // Poll every 20 seconds
  };

  // Custom event handling functions
  const handleNewUserPlaceEvent = async (event) => {
    try {
      const { user, referrer, matrix, level, place } = event.returnValues;
      // console.log(
      //   `NewUserPlace - User: ${user}, Referrer: ${referrer}, Matrix: ${matrix}, Level: ${level}, Place: ${place}`
      // );

      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const userData = await contract.methods.users(`${user}`).call();
      const uid = userData.id;

      const userData_ref = await contract.methods.users(`${referrer}`).call();
      const sid = userData_ref.id;

      axios
        .post("https://adazo-api.nakshtech.info/InsertUsers", {
          uid: parseInt(uid),
          sid: parseInt(sid),
          userAddress: `${user}`,
          refAddress: `${referrer}`,
          Matrix: `${matrix}`,
          Level: `${level}`,
          Place: `${place}`,
        })
        .then((response) => {
        //  console.log("InsertUsers", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching InsertUsers: ", error);
        });
    } catch (error) {
      console.error("Error fetching handleNewUserPlaceEvent: ", error);
    }
  };

  const handleReinvestEvent = async (event) => {
    try {
      const { user, currentReferrer, caller, matrix, level, mtype } =
        event.returnValues;
      // console.log(
      //   `Reinvest - User: ${user}, Current Referrer: ${currentReferrer}, Caller: ${caller}, Matrix: ${matrix}, Level: ${level}, MType: ${mtype}`
      // );

      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const userData = await contract.methods.users(`${user}`).call();
      const uid = userData.id;

      let sid = 0;
      if (
        `${currentReferrer}` != "0x0000000000000000000000000000000000000000"
      ) {
        const userData_ref = await contract.methods
          .users(`${currentReferrer}`)
          .call();
        sid = userData_ref.id;
      }

      axios
        .post("https://adazo-api.nakshtech.info/InsertReinvestData", {
          uid: parseInt(uid),
          sid: parseInt(sid),
          userAddress: `${user}`,
          refAddress: `${currentReferrer}`,
          caller: `${caller}`,
          matrix: `${matrix}`,
          level: `${level}`,
          mtype: `${mtype}`,
        })
        .then((response) => {
         // console.log("InsertReinvestData", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching InsertReinvestData: ", error);
        });
    } catch (error) {
      console.log("Error during handleReinvestEvent", error);
    }
  };

  // New function to handle Payment events
  const handlePaymentEvent = async (event) => {
    try {
      const { from, to, value, mtype } = event.returnValues;
      // console.log(
      //   `Payment - From: ${from}, To: ${to}, Value: ${value}, MType: ${mtype}`
      // );

      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const userData = await contract.methods.users(`${to}`).call();
      const uid = userData.id;

      const userData_ref = await contract.methods.users(`${from}`).call();
      const fromid = userData_ref.id;

      // console.log("valueInWei", `${value}`);

      let valueInWei = `${value}`; // 1.5 ether in wei
      //console.log("valueInWei 111", valueInWei);

      // Convert from wei to ether
      let valueInEther =
        parseFloat(valueInWei) / parseFloat(1000000000000000000); //  web3.utils.fromWei(valueInWei);
      // console.log("valueInEther", valueInEther);

      axios
        .post("https://adazo-api.nakshtech.info/InsertUserPaymentsData", {
          uid: parseInt(uid),
          fromid: parseInt(fromid),
          fromAddress: `${from}`,
          toAddress: `${to}`,
          amount: parseFloat(valueInEther),
          mtype: parseInt(mtype),
        })
        .then((response) => {
         // console.log("InsertPaymentData", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching InsertPaymentData: ", error);
        });
    } catch (error) {
      console.log("Error during handlePaymentEvent", error);
    }
  };

  return (
    <div>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p>Sign in to continue to Adazo Matrix.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img
                        src="Dashboardassets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="p-2">
                    <form className="form-horizontal" action="">
                      <h5 className="text-white">
                        Access your personal account
                      </h5>
                      <h5
                        id="metamaskConnection"
                        style={{
                          color: "green",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        {walletAddress !== "Connect to wallet"
                          ? `Connected: ${walletAddress}`
                          : "Wallet is locked"}
                      </h5>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={register_}
                        disabled={btnDisable}
                      > 
                        Register
                      </button>
                      <h5 className="mt-3 text-white">
                        Remember to Authorize with the Correct Address.
                      </h5>
                      <h5 className="mt-3 text-white">View an Account</h5>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control m-3"
                          id="username"
                          placeholder="Please Enter ID or Address"
                          value={uplineAddress}
                          onChange={(e) => setUplineAddress(e.target.value)}
                        />
                      </div>
                      <h5 className="mt-3 text-white">
                        Enter the Account ID or Address
                      </h5>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="button"
                          onClick={connectToTheWallet}
                        >
                          {inputValue}
                        </button>
                      </div>
                      <div className="mt-3 text-center">
                        <div>
                          <p className="text-white">
                            If you have already registered then{" "}
                            <Link
                              to="/Login"
                              className="fw-medium text-primary"
                            >
                              Login
                            </Link>
                          </p>
                          <p className="text-white">
                            Copyright © 2024 adazo.io. All Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
