import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import DirectTeamReport from "../TeamReports/DirectTeamReport";
import LevelteamReport from "../TeamReports/LevelteamReport";
import DownlineteamReport from "../TeamReports/DownlineteamReport";
import DirectIncomeReport from "../IncomeReports/DirectIncomeReport";
import A2_Income from "../IncomeReports/A2_Income";
import A3_Income from "../IncomeReports/A3_Income";
import MyShareReport from "../MyShareReport/MyShareReport";
import UpgradeHistory from "../TeamReports/UpgradeHistory";
import Partner from "../Partner/Partner";
import Stats from "../Stats/Stats";
import Tree_View from "../Tree_View/Tree_View";
import WithdrawalHistory from "../Withdrawal/WithdrawalHistory";
import Withdrawal from "../Withdrawal/Withdrawal";
import X3 from "../X3/X3";
import { useDispatch, useSelector } from "react-redux";
import {
  contractAddress,
  abi,
  loadWeb3,
  X3contract,
  X3abi,
  ctocontract,
  ctoabi,
  Withdrawalabi,
  Withdrawalcontract,
} from "../../connectivity/api";
import Web3 from "web3";
import { toast } from "react-toastify";
import Slot1 from "../Slot1/Slot1";
import Slot2 from "../Slot2/Slot2";
import Slot3 from "../Slot3/Slot3";
import Slot4 from "../Slot4/Slot4";
import Slot5 from "../Slot5/Slot5";
import Slot6 from "../Slot6/Slot6";
import Slot7 from "../Slot7/Slot7";
import Slot8 from "../Slot8/Slot8";
import Slot9 from "../Slot9/Slot9";
import Slot10 from "../Slot10/Slot10";
import Slot11 from "../Slot11/Slot11";
import Slot12 from "../Slot12/Slot12";
import { totalteamsize, MainID } from "../../Actions";
import { useraddress, useraddressinshort } from "../../Actions";
import X3Slot1 from "../X3Slot1/X3Slot1";
import X3Slot2 from "../X3Slot2/X3Slot2";
import X3Slot3 from "../X3Slot3/X3Slot3";
import X3Slot4 from "../X3Slot4/X3Slot4";
import X3Slot5 from "../X3Slot5/X3Slot5";
import X3Slot6 from "../X3Slot6/X3Slot6";
import X3Slot7 from "../X3Slot7/X3Slot7";
import X3Slot8 from "../X3Slot8/X3Slot8";
import X3Slot9 from "../X3Slot9/X3Slot9";
import X3Slot10 from "../X3Slot10/X3Slot10";
import X3Slot11 from "../X3Slot11/X3Slot11";
import X3Slot12 from "../X3Slot12/X3Slot12";
import logo_dark from "../../assets/logo_dark.png";
import wallet from "../../assets/wallet.png";
import avatar_1 from "../../assets/avatar_1.jpg";
import axios from "axios";

import Income1 from "../Income/Income1";
import Income2 from "../Income/Income2";
import Income3 from "../Income/Income3";
import { API } from "../../Utils/Api";

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(true);
  const myStateshort = useSelector((state) => state.Usermatamaskshortaddress);
  const [matamaskaddress, setmatamaskaddress] = useState();
  const dispatch = useDispatch();
  const [wattleaddress, setwattleaddress] = useState("");
  const LoginAddress = sessionStorage.getItem("UserAddress"); //myState.address
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
      let realaddress = myStateshort.address;
      setmatamaskaddress(realaddress);
    };

    handleResize(); // Check the size on initial render
    window.addEventListener("resize", handleResize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup the event listener
    };
  }, []);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const connectaddress = async () => {
    try {
      let address = await loadWeb3();
      setwattleaddress(address);
      setmatamaskaddress(address.slice(0, 3) + "..." + address.slice(38, 42));
      dispatch(useraddress(address.toString()));
      dispatch(
        useraddressinshort(address.slice(0, 3) + "..." + address.slice(38, 42))
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  const activeGoldInfos = async () => {
    let userAddress = await loadWeb3();
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress);

    const result = await contract.methods
      .activeGoldInfos(userAddress, 1)
      .call();
    console.log("activeGoldInfos", result);
    // console.log("activeGoldInfos currentParent", parseInt(result.currentParent));
    // console.log("activeGoldInfos position", parseInt(result.position));

    const userData = await contract.methods.users(userAddress).call();
    const uid = userData.id;
    let sid = parseInt(result.currentParent);
    // console.log("sid",sid);
    let position = parseInt(result.position);
    const refAddress = await contract.methods.idToAddress(sid).call();
    //console.log("userData_ref",userData_ref);

    axios
      .post("https://adazo-api.nakshtech.info/InsertAlllUsers", {
        uid: parseInt(uid),
        sid: sid,
        refAddress: refAddress,
        userAddress: userAddress,
        position: position,
      })
      .then((response) => {
        console.log("InsertAlllUsers", response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching InsertAlllUsers: ", error);
      });
  };

  const activeGoldInfosInsert = async (uid) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress);

    const userAddress = await contract.methods.idToAddress(uid).call();

    const result = await contract.methods
      .activeGoldInfos(userAddress, 1)
      .call();
    console.log("activeGoldInfos", result);
    // console.log("activeGoldInfos currentParent", parseInt(result.currentParent));
    // console.log("activeGoldInfos position", parseInt(result.position));

    let sid = parseInt(result.currentParent);
    // console.log("sid",sid);
    let position = parseInt(result.position);
    const refAddress = await contract.methods.idToAddress(sid).call();
    //console.log("userData_ref",userData_ref);

    axios
      .post("https://adazo-api.nakshtech.info/InsertAlllUsers", {
        uid: parseInt(uid),
        sid: sid,
        refAddress: refAddress,
        userAddress: userAddress,
        position: position,
      })
      .then((response) => {
        console.log("InsertAlllUsers", response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching InsertAlllUsers: ", error);
      });
  };

  // for(let i=20;i<=22;i++)
  // {
  //   console.log(i);
  //   activeGoldInfosInsert(i);
  // }
  // //activeGoldInfosInsert(4);

  // useEffect(() => {
  //   activeGoldInfos();
  // },[])

  const InsertRecyleId = async () => {
    try {
      let userAddress = await loadWeb3();
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);

      const userData = await contract.methods.users(userAddress).call();
      const uid = userData.id;

      let response = await API.post("/GetUserRecycleData", {
        uid: parseInt(uid),
        package: 1,
        type: "A2",
      });
      //console.log("GetUserRecycleData", response.data.data);

      if (response.data.data[0] != undefined) {
        console.log("GetUserRecycleData uid", response.data.data[0].uid);
        let reCycleId = response.data.data[0].uid;
        let reCycleFromid = response.data.data[0].fromid;
        if (reCycleId !== undefined) {
          const reCycleAddress = await contract.methods
            .idToAddress(reCycleId)
            .call();

          const result = await contract.methods
            .activeGoldInfos(reCycleAddress, 1)
            .call();

          console.log("activeGoldInfos", result);
          // console.log("activeGoldInfos currentParent", parseInt(result.currentParent));
          // console.log("activeGoldInfos position", parseInt(result.position));

          let res = await API.post("/InsertUserRecyle", {
            uid: parseInt(uid),
            fromid: reCycleFromid,
            currentParentId: parseInt(result.currentParent),
            position: parseInt(result.position),
            package: 1,
            type: "A2",
          });
          res = res.data.data;
          console.log("InsertUserRecyle", res);
        }
      }
    } catch (error) {
      console.log("Error while in Insert Reclycle", error);
    }
  };

  useEffect(() => {
    // Api();
    InsertRecyleId();
  }, []);

  const Api = async () => {
    try {
      let ref = "0x37D17Fa6eA818B1782a7620e57e3948a3cd2Ef33";
      let walletAddress = await loadWeb3();
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      /// Registaration API call
      let userData = await contract.methods.users(walletAddress).call();
      let uid = userData.id;
      let sponserData = await contract.methods.users(ref).call();
      let sid = sponserData.id;

      let result = await contract.methods
        .activeGoldInfos(walletAddress, 1)
        .call();
      let position = parseInt(result.position);
      let uplineId = parseInt(result.currentParent);
      let uplineAddress = await contract.methods.idToAddress(uplineId).call();

      console.log(
        "Registartion",
        parseInt(uid),
        "sid:",
        parseInt(sid),
        " uplineId:",
        parseInt(uplineId),
        " refAddress: ",
        ref,
        "userAddress: ",
        walletAddress,
        "uplineAddress:",
        uplineAddress,
        "position:",
        position
      );
      let res = await API.post("/InsertAlllUsers", {
        uid: parseInt(uid),
        sid: parseInt(sid),
        uplineId: parseInt(uplineId),
        refAddress: ref,
        userAddress: walletAddress,
        uplineAddress: uplineAddress,
        position: position,
        package: 1,
        type: "",
      });
      console.log("Registartion API", res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <>
        <header
          id="page-topbar"
          style={{
            background: "#30188a",
          }}
        >
          <div className="navbar-header">
            <div className="d-flex">
              {/* LOGO */}
              <div className="navbar-brand-box">
                <Link to="/Dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo_dark} alt="" width="60px" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo_dark} width="60px" />
                  </span>
                </Link>
                <Link to="/Dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logo_dark} width="60px" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo_dark} width="60px" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
                onClick={toggleSidebar}
              >
                <i className="fa fa-fw fa-bars text-white" />
              </button>
              {/* App Search*/}
              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={""}
                  />
                  <button className="buttonse"> Go </button>
                </div>
              </form> */}
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                {/* <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="mdi mdi-magnify"></i>
                      </button> */}
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="dropdown bges d-inline-block">
                <button
                  type="button"
                  className="btn header-item1 waves-effect"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src={wallet} width="20px" />
                  <span
                    className=" text-white d-xl-inline-block ms-1"
                    key="t-henry"
                  >
                    {LoginAddress.slice(0, 4) +
                      "..." +
                      LoginAddress.slice(38, 42)}
                  </span>
                  <img
                    className="rounded-circle header-profile-user"
                    src={avatar_1}
                    alt="Header Avatar"
                  />
                </button>
                <div className="dropdown-menu  dropdown-menu-end">
                  {/* item*/}
                  <a className="dropdown-item" href="#">
                    {/* <img
                      src="Dashboardassets/images/dashboard.png"
                      width="13px"
                    />{" "} */}
                    <span key="t-profile">Dashboard</span>
                  </a>
                  <a className="dropdown-item" href="#">
                    {/* <i className="bx bx-wallet font-size-16 align-middle me-1" />{" "} */}
                    <span key="t-my-wallet" onClick={connectaddress}>
                      My Wallet
                    </span>
                  </a>
                  <div className="dropdown-divider" />
                  {/* <a
                    className="dropdown-item d-flex justify-content-between text-danger"
                    href="#"
                  >
                    <span key="t-logout" className="text-black">
                      {matamaskaddress}
                    </span>
                    <img src="Dashboardassets/images/copy.png" className="imgesww" width="20px"/>{" "}
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* ========== Left Sidebar Start ========== */}
        <div className={`vertical-menu ${isSidebarOpen ? "open" : "closed"}`}>
          <div data-simplebar="" className="h-100">
            {/*- Sidemenu */}
            <div id="sidebar-menu">
              {/* Left Menu Start */}
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menucss">
                  <Link
                    to="/Dashboard"
                    onClick={handleLinkClick}
                    className="waves-effect"
                  >
                    <div className="backs">
                      <i className="bx bx-home-circle" />
                      <span key="t-chat">Dashboard</span>
                    </div>
                  </Link>
                </li>
                <li className="menucss">
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                    onClick={toggleDropdown}
                  >
                    <i className="fa fa-users" aria-hidden="true" />
                    <span key="t-dashboards">Team</span>
                  </a>
                  <ul
                    className={`sub-menu ${isDropdownOpen ? "open" : "closed"}`}
                    aria-expanded="false"
                  >
                    <li>
                      <Link
                        to="/Dashboard/DirectTeamReport"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Direct Team
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/Dashboard/LevelteamReport"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Level Details
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/Dashboard/DownlineteamReport"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Downline Team
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/Dashboard/Partner"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Patners
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/Dashboard/Stats"
                        key="t-saas"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-futbol-o" aria-hidden="true" />
                        Stats
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li className="menucss">
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                    onClick={toggleDropdown}
                  >
                    <i className="fa fa-users" aria-hidden="true" />
                    <span key="t-dashboards">Income</span>
                  </a>
                  <ul
                    className={`sub-menu ${isDropdownOpen ? "open" : "closed"}`}
                    aria-expanded="false"
                  >
                    <li>
                      <Link
                        to="/Dashboard/DirectIncomeReport"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Direct Income
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Dashboard/A2_Income"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        A2 Income
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Dashboard/A3_Income"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        A3 Income
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Dashboard/MyShareReport"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        My Share
                      </Link>
                    </li>
                    <li>
                      <Link to="" key="t-default" onClick={handleLinkClick}>
                        <i className="fa fa-user" aria-hidden="true" />
                        Share Pool
                      </Link>
                    </li>
                    <li>
                      <Link to="" key="t-default" onClick={handleLinkClick}>
                        <i className="fa fa-user" aria-hidden="true" />
                        Infinity Pool
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="" key="t-default" onClick={handleLinkClick}>
                        <i className="fa fa-user" aria-hidden="true" />
                        A2 Level Income
                      </Link>
                    </li>
                    <li>
                      <Link to="" key="t-default" onClick={handleLinkClick}>
                        <i className="fa fa-user" aria-hidden="true" />
                        A3 Level Income
                      </Link>
                    </li> */}

                    {/* <li>
                      <Link to="" key="t-default" onClick={handleLinkClick}>
                        <i className="fa fa-user" aria-hidden="true" />
                        Total Income
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li className="menucss">
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                    onClick={toggleDropdown}
                  >
                    <i className="fa fa-users" aria-hidden="true" />
                    <span key="t-dashboards">Withdrawal</span>
                  </a>
                  <ul
                    className={`sub-menu ${isDropdownOpen ? "open" : "closed"}`}
                    aria-expanded="false"
                  >
                    <li>
                      <Link
                        to="/Dashboard/Withdrawal"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Withdrawal
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Dashboard/WithdrawalHistory"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Withdrawal History
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="menucss">
                  <Link to="/Dashboard/UpgradeHistory" className="waves-effect">
                    <i className="bx bx-file" />
                    <span key="t-file-manager">Upgrade History</span>
                  </Link>
                </li>
                {/* <li className="menucss">
                  <a href="#" className="waves-effect">
                    <i className="bx bx-file" />
                    <span key="t-file-manager">Information</span>
                  </a>
                </li>  */}
                <li className="menucss">
                  <Link
                    to="/"
                    className="waves-effect"
                    onClick={() => {
                      sessionStorage.clear();
                    }}
                  >
                    <i className="fa fa-sign-out" aria-hidden="true" />
                    <span key="t-chat">Exit</span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* Sidebar */}
          </div>
        </div>
        {/* Left Sidebar End */}
        {/* ============================================================== */}
        {/* Start right Content here */}
        {/* ============================================================== */}
        <div className={`main-content ${isSidebarOpen ? "open" : "closed"}`}>
          <Routes>
            <Route path="/" element={<DefaultContent />} />
            <Route path="UpgradeHistory" element={<UpgradeHistory />} />
            <Route path="Withdrawal" element={<Withdrawal />} />
            <Route path="WithdrawalHistory" element={<WithdrawalHistory />} />
            <Route path="DirectTeamReport" element={<DirectTeamReport />} />
            <Route path="LevelteamReport" element={<LevelteamReport />} />
            <Route path="DownlineteamReport" element={<DownlineteamReport />} />
            <Route path="DirectIncomeReport" element={<DirectIncomeReport />} />
            <Route path="Partner" element={<Partner />} />
            <Route path="Stats" element={<Stats />} />
            <Route path="X2" element={<Tree_View />} />
            <Route path="X3" element={<X3 />} />
            <Route path="/Slot1" element={<Slot1 />} />
            <Route path="/Slot2" element={<Slot2 />} />
            <Route path="/Slot3" element={<Slot3 />} />
            <Route path="/Slot4" element={<Slot4 />} />
            <Route path="/Slot5" element={<Slot5 />} />
            <Route path="/Slot6" element={<Slot6 />} />
            <Route path="/Slot7" element={<Slot7 />} />
            <Route path="/Slot8" element={<Slot8 />} />
            <Route path="/Slot9" element={<Slot9 />} />
            <Route path="/Slot10" element={<Slot10 />} />
            <Route path="/Slot11" element={<Slot11 />} />
            <Route path="/Slot12" element={<Slot12 />} />

            <Route path="/X3Slot1" element={<X3Slot1 />} />
            <Route path="/X3Slot2" element={<X3Slot2 />} />
            <Route path="/X3Slot3" element={<X3Slot3 />} />
            <Route path="/X3Slot4" element={<X3Slot4 />} />
            <Route path="/X3Slot5" element={<X3Slot5 />} />
            <Route path="/X3Slot6" element={<X3Slot6 />} />
            <Route path="/X3Slot7" element={<X3Slot7 />} />
            <Route path="/X3Slot8" element={<X3Slot8 />} />
            <Route path="/X3Slot9" element={<X3Slot9 />} />
            <Route path="/X3Slot10" element={<X3Slot10 />} />
            <Route path="/X3Slot11" element={<X3Slot11 />} />
            <Route path="/X3Slot12" element={<X3Slot12 />} />

            <Route path="/Income1" element={<Income1 />} />
            <Route path="/Income2" element={<Income2 />} />
            <Route path="/Income3" element={<Income3 />} />

            <Route path="A2_Income" element={<A2_Income />} />
            <Route path="A3_Income" element={<A3_Income />} />
            <Route path="MyShareReport" element={<MyShareReport />} />

            
          </Routes>
          {/* end main content*/}
        </div>
        {/* END layout-wrapper */}
        {/* Right bar overlay*/}
        <div className="rightbar-overlay" />
      </>
    </div>
  );
};

const DefaultContent = () => {
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress"); //myState.address
  const LoginId =
    sessionStorage.getItem("UserId").toString() != null
      ? parseInt(sessionStorage.getItem("UserId").toString())
      : 0; //myState.address
  const myStateshort = useSelector((state) => state.Usermatamaskshortaddress);

  const [matamaskaddressshort, setmatamaskaddressshort] = useState();
  const [X3Amount, setX3Amount] = useState();
  const [MembersTotal, setMembersTotal] = useState("0");
  const [TotalContractEarn, setTotalContractEarn] = useState("0");
  const [ctoincome, setctoincome] = useState(0);

  const [Partners, setPartners] = useState("0");
  const [totalteam, settotalteam] = useState("0");
  const [userearn, setuserearn] = useState("0");
  const [userid, setuserid] = useState(0);
  const [userAmount, setuserAmount] = useState("0");
  const [REf_URL, setREf_URL] = useState(
    "https://adazo-user.netlify.app/Register?ref="
  );
  const [star1, setstar1] = useState(0);
  const [star2, setstar2] = useState(0);
  const [star3, setstar3] = useState(0);
  const [star4, setstar4] = useState(0);
  const [star5, setstar5] = useState(0);
  const [star6, setstar6] = useState(0);
  const [star7, setstar7] = useState(0);
  const [star8, setstar8] = useState(0);
  const [star9, setstar9] = useState(0);
  const [star10, setstar10] = useState(0);
  const [star11, setstar11] = useState(0);

  const setCopySuccess = () => toast("Text copied successfully");

  // const userinfo = async () => {
  //   try {
  //     console.log("LoginAddress-->A", LoginAddress.toString());
  //     let team = 0;
  //     let t = 0;
  //     window.web3 = new Web3(window.ethereum);
  //     let contract = new window.web3.eth.Contract(abi, contractAddress);
  //     let CTOcontract = new window.web3.eth.Contract(ctoabi, ctocontract);
  //     let X3__contract = new window.web3.eth.Contract(X3abi, X3contract);

  //     let a = await contract.methods.users(LoginAddress.toString()).call();

  //     let _X3a = await X3__contract.methods.Users_3X(LoginAddress.toString()).call();

  //     let ctoincome = await CTOcontract.methods.ctoAmount(LoginAddress.toString()).call();
  //     setctoincome(parseFloat(window.web3.utils.fromWei(ctoincome, "ether")).toFixed(2));

  //     let ids = await contract.methods.lastUserId().call();
  //     let totalAmount = "0"; //await contract.methods.totalAmount().call();

  //     // let activeids = await contract.methods
  //     //   .check_useractive_id_List(LoginAddress, 1)
  //     //   .call();
  //     // for (let w = 0; w < activeids.length; w++) {
  //     //   let totalteam1 = await contract.methods
  //     //     .User_Referral(parseInt(activeids[w]))
  //     //     .call();
  //     //   team = parseInt(team) + parseInt(totalteam1.referrals);
  //     //   t = parseInt(t) + parseInt(totalteam1.referrals);
  //     // }
  //     // let xxx = await contract.methods.User_Referral(parseInt(a.id)).call();

  //     console.log("LoginAddress-->", a);
  //     console.log("LoginAddress-->", LoginAddress);
  //     let Earn = parseInt(a.Earn);
  //     let firstid = parseInt(a.id);
  //     let amount = window.web3.utils.fromWei(parseInt(a.Totalslotrevenue),"ether");
  //     // let x3a = await contract.methods.Users_3x(LoginAddress).call();
  //     // let totalAx3 = parseInt(_X3a.Earn) + parseInt(x3a.Earn);
  //     // setX3Amount(window.web3.utils.fromWei(parseInt(totalAx3), "ether"));
  //     settotalteam(parseInt(team));
  //     dispatch(totalteamsize(parseInt(t)));
  //     dispatch(MainID(parseInt(a.mainId)));

  //     setuserid(parseInt(firstid));
  //     // setuserearn(
  //     //   parseFloat(
  //     //     window.web3.utils.fromWei(Earn + parseInt(totalAx3), "ether")
  //     //   ).toFixed(2)
  //     // );
  //     // setPartners(parseInt(xxx.referrals));
  //     setMembersTotal(parseInt(ids));
  //     setuserAmount(
  //       parseFloat(window.web3.utils.fromWei(Earn, "ether")).toFixed(2)
  //     );
  //     setTotalContractEarn(
  //       window.web3.utils.fromWei(parseInt(totalAmount), "ether")
  //     );
  //     const baseUrl = window.location.origin;
  //     const url = `${baseUrl}/register?ref=${LoginAddress}`;
  //     setREf_URL(url);
  //     /*
  //     let ata = await contract.methods.Users1(LoginAddress).call();
  //     let ata_X3a = await X3__contract.methods
  //       .Users_3X1(LoginAddress)
  //       .call();
  //     if (parseInt(a.mainId) > 0) {
  //       setstar1(1);
  //     }
  //     if (parseInt(a.mainId_slot2) > 0) {
  //       setstar2(1);
  //     }
  //     if (parseInt(ata.mainId_slot3) > 0) {
  //       setstar3(1);
  //     }
  //     if (parseInt(ata.mainId_slot4) > 0) {
  //       setstar4(1);
  //     }
  //     if (parseInt(ata.mainId_slot5) > 0) {
  //       setstar5(1);
  //     }
  //     if (parseInt(ata.mainId_slot6) > 0) {
  //       setstar6(1);
  //     }

  //     if (parseInt(_X3a.mainId_slot2) > 0) {
  //       setstar7(1);
  //     }
  //     if (parseInt(ata_X3a.mainId_slot3) > 0) {
  //       setstar8(1);
  //     }
  //     if (parseInt(ata_X3a.mainId_slot4) > 0) {
  //       setstar9(1);
  //     }
  //     if (parseInt(ata_X3a.mainId_slot5) > 0) {
  //       setstar10(1);
  //     }
  //     if (parseInt(ata_X3a.mainId_slot6) > 0) {
  //       setstar11(1);
  //     }
  //       */
  //   } catch (error) {
  //     console.log("dashboard Error:", error);
  //   }
  // };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(REf_URL).then(() => {
      setCopySuccess("Text copied successfully!");
    });
  };

  const [teambuiness1, setteambuiness1] = useState(0);
  const [teambuiness2, setteambuiness2] = useState(0);
  const [myrank, setmyrank] = useState(0);
  const [UserShare0, setUserShare0] = useState(0);
  const [UserShare1, setUserShare1] = useState(0);
  const [UserShare2, setUserShare2] = useState(0);
  const [UserShare3, setUserShare3] = useState(0);
  const [infinitypoolFund24, setinfinitypoolFund24] = useState(0);
  const [infinitypoolfund25, setinfinitypoolfund25] = useState(0);

  const [dividend, setdividend] = useState(0);
  const [infinitypoolincome, setinfinitypoolincome] = useState(0);
  const [withdraw, setwithdraw] = useState(0);
  const [TotalIncome, setTotalIncome] = useState(0);

  const ContractDataBind = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress);

    // const resultusersdividendshare = await contract.methods.dividendshare(LoginId).call();
    // console.log("resultusers-->dividendshare",resultusersdividendshare);
    // const resultusersdividendpoolFund = await contract.methods.dividendpoolFund(LoginId).call();
    // console.log("resultusers-->dividendpoolFund",resultusersdividendpoolFund);
    // const resultusersdividendfund = await contract.methods.dividendfund(LoginId).call();
    // console.log("resultusers-->dividendfund",(parseFloat(resultusersdividendfund) / 10 ** 18).toFixed(2));

    const resultBusiness = await contract.methods
      .getTeamBusiness(LoginAddress)
      .call();
    setteambuiness1((parseFloat(resultBusiness[0]) / 10 ** 18).toFixed(2));
    setteambuiness2((parseFloat(resultBusiness[1]) / 10 ** 18).toFixed(2));

    const resultRank = await contract.methods.usersRank(LoginAddress).call();
    setmyrank(parseInt(resultRank));

    const resultShare1 = await contract.methods
      .usersShare(LoginAddress, 1)
      .call();
    const resultShare2 = await contract.methods
      .usersShare(LoginAddress, 2)
      .call();
    const resultShare3 = await contract.methods
      .usersShare(LoginAddress, 3)
      .call();
    const resultShare4 = await contract.methods
      .usersShare(LoginAddress, 4)
      .call();
    const resultShare5 = await contract.methods
      .usersShare(LoginAddress, 5)
      .call();
    const resultShare6 = await contract.methods
      .usersShare(LoginAddress, 6)
      .call();
    const resultShare7 = await contract.methods
      .usersShare(LoginAddress, 7)
      .call();
    const resultShare8 = await contract.methods
      .usersShare(LoginAddress, 8)
      .call();
    const resultShare9 = await contract.methods
      .usersShare(LoginAddress, 9)
      .call();
    const resultShare10 = await contract.methods
      .usersShare(LoginAddress, 10)
      .call();
    const resultShare11 = await contract.methods
      .usersShare(LoginAddress, 11)
      .call();
    const resultShare12 = await contract.methods
      .usersShare(LoginAddress, 11)
      .call();
    setUserShare2(
      parseInt(resultShare1[1]) +
        parseInt(resultShare2[1]) +
        parseInt(resultShare3[1]) +
        parseInt(resultShare4[1]) +
        parseInt(resultShare5[1]) +
        parseInt(resultShare6[1]) +
        parseInt(resultShare7[1]) +
        parseInt(resultShare8[1]) +
        parseInt(resultShare9[1]) +
        parseInt(resultShare10[1]) +
        parseInt(resultShare11[1]) +
        parseInt(resultShare12[1])
    );

    setUserShare0(parseFloat(resultShare1[0]) / 10 ** 18);
    setUserShare1(parseInt(resultShare1[2]));
    setUserShare3(parseFloat(resultShare1[3]) / 10 ** 18);

    const resultinfinitypoolFund24 = await contract.methods
      .infinitypoolFund()
      .call();
    setinfinitypoolFund24(
      (parseFloat(resultinfinitypoolFund24[0]) / 10 ** 18).toFixed(2)
    );

    const resultinfinitypoolfund25 = await contract.methods
      .infinitypoolfund()
      .call();
    setinfinitypoolfund25(
      (parseFloat(resultinfinitypoolfund25) / 10 ** 18).toFixed(2)
    );

    const contractWithdrawal = new web3.eth.Contract(
      Withdrawalabi,
      Withdrawalcontract
    );
    const resultBalance = await contractWithdrawal.methods
      .userspool(LoginAddress)
      .call();
    setdividend((parseFloat(resultBalance[0]) / 10 ** 18).toFixed(2));
    setinfinitypoolincome((parseFloat(resultBalance[1]) / 10 ** 18).toFixed(2));
    setwithdraw((parseFloat(resultBalance[2]) / 10 ** 18).toFixed(2));
    setTotalIncome(
      (
        parseFloat(dividend) +
        parseFloat(infinitypoolincome) +
        parseFloat(withdraw)
      ).toFixed(2)
    );
  };

  const [dashData, setdashData] = useState([]);
  const Dashboard_API = async () => {
    try {
      const baseUrl = window.location.origin;
      const url = `${baseUrl}/register?ref=${LoginAddress}`;
      setREf_URL(url);
      let response = await API.post("/user_details", {
        UserId: LoginId,
      });
      response = response.data.data[0][0];

      setdashData(response);
    } catch (error) {}
  };

  useEffect(() => {
    // userinfo();
    Dashboard_API();
    // ContractDataBind();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      ContractDataBind();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="page-content">
      <div className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="newsw">
                <div className="">
                  <img
                    src={avatar_1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <div className="adse">
                  <h2 style={{ color: "#fff", marginTop: "0px" }}>
                    ID {LoginId}
                  </h2>
                  <a
                    className="dropdown-item d-flex justify-content-between text-danger"
                    href="#"
                  >
                    <span key="t-logout" className="text-white">
                      {LoginAddress.slice(0, 4) +
                        "..." +
                        LoginAddress.slice(38, 42)}
                    </span>{" "}
                    &nbsp; &nbsp;
                    <img
                      src="Dashboardassets/images/copy.png"
                      className="tese"
                      width="20px"
                    />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="text-muted text-white">Personal link</h5>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <input
                      type="text"
                      id="fname"
                      className="inputes"
                      defaultValue={REf_URL}
                      value={REf_URL}
                      name="fname"
                    />
                  </span>
                  <button
                    className="btn coped btn-primary"
                    onClick={copyToClipboard}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="mb-2 text-muted colow fw-medium">
                            Partners
                          </p>
                          <h4 className="mb-0 colow">{dashData?.DirectTeam}</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon ksfkw avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="fa fa-user font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="mb-2 text-muted colow fw-medium">
                            Team
                          </p>
                          <h4 className="mb-0 colow">{dashData?.TotalTeam}</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="fa fa-users font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-2 text-muted colow fw-medium">
                            Direct Income
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h4 className="mb-0 colow">
                            {dashData?.DirectIncome} ADA
                          </h4>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            CTO Income
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{ctoincome} USDT</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-2 text-muted colow fw-medium">
                            My Rank
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h4 className="mb-0 colow">{myrank}</h4>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mini-stats-wid">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="mb-2 text-muted colow fw-medium">
                        Leg 1 Business
                      </p>
                      <h4 className="mb-0 colow">{teambuiness1}</h4>
                    </div>
                    <div className="flex-shrink-0 align-self-center">
                      <div className="mini-stat-icon ksfkw avatar-sm rounded-circle bg-primary">
                        <span className="avatar-title">
                          <i className="fa fa-user font-size-24" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card mini-stats-wid">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="mb-2 text-muted colow fw-medium">
                        Leg 2 Business
                      </p>
                      <h4 className="mb-0 colow">{teambuiness2}</h4>
                    </div>
                    <div className="flex-shrink-0 align-self-center">
                      <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                        <span className="avatar-title">
                          <i className="fa fa-users font-size-24" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            My Share
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{UserShare0}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            Share Income
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{UserShare1}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            My Share
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{UserShare2}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            Share Income
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{UserShare3}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            Infinity Pool Fund
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{infinitypoolFund24}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            A2 Income
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{dashData.A2Income}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            A3 Income
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{dashData.A3Income}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="flex-grow-1">
                          <p className="mb-0 text-muted colow fw-medium">
                            Net Income
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h4 className="mb-2 colow">{dashData.TotalIncome}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i className="fa fa-users font-size-24" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="widthhhVal">
                  <h2 className="d-flex mt-3">
                    Adazo Programs &nbsp;
                    <button className="dss d-flex justify-content-between">
                      <i
                        className="fa fa-question-circle fotes"
                        aria-hidden="true"
                      />
                      <span className="text-base sm:text-sm fontr">
                        {" "}
                        &nbsp;Info{" "}
                      </span>
                    </button>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="widthhhVal">
                  <div className="card mini-stats-wid">
                    <div className="card-body mb-4">
                      <div className="d-flex justify-content-between mb-4 mt-4">
                        <div className="">
                          <h4 className="mb-2 colow">Adazo A2</h4>
                        </div>
                        <div className="">
                          <h4 className="mb-2 colow">{userAmount} ADA</h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <div className="d-flex mb-2">
                            <div className={star1 === 1 ? "bgw" : "bgw1"} />
                            <div className={star1 === 1 ? "bgw" : "bgw1"} />
                            <div className={star2 === 1 ? "bgw" : "bgw1"} />
                            <div className={star3 === 1 ? "bgw" : "bgw1"} />
                          </div>
                          <div className="d-flex mb-2">
                            <div className={star4 === 1 ? "bgw" : "bgw1"} />
                            <div className={star4 === 1 ? "bgw" : "bgw1"} />
                            <div className={star5 === 1 ? "bgw" : "bgw1"} />
                            <div className={star6 === 1 ? "bgw" : "bgw1"} />
                          </div>
                          <div className="d-flex mb-2">
                            <div className={star4 === 1 ? "bgw" : "bgw1"} />
                            <div className={star4 === 1 ? "bgw" : "bgw1"} />
                            <div className={star5 === 1 ? "bgw" : "bgw1"} />
                            <div className={star6 === 1 ? "bgw" : "bgw1"} />
                          </div>
                        </div>
                        <div className="mt-10">
                          <Link
                            to="/Dashboard/X2"
                            className="btn btn-primary waves-effect waves-light pre btn-sm"
                          >
                            Preview <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="widthhhVal">
                  <div className="card mini-stats-wid">
                    <div className="card-body mb-4">
                      <div className="d-flex justify-content-between mb-4 mt-4">
                        <div className="">
                          <h4 className="mb-2 colow">Adazo A3</h4>
                        </div>
                        <div className="">
                          <h4 className="mb-2 colow">{X3Amount} ADA</h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <div className="d-flex mb-2">
                            <div className={star1 === 1 ? "bgw" : "bgw1"} />
                            <div className={star1 === 1 ? "bgw" : "bgw1"} />
                            <div className={star7 === 1 ? "bgw" : "bgw1"} />
                            <div className={star8 === 1 ? "bgw" : "bgw1"} />
                          </div>
                          <div className="d-flex mb-2">
                            <div className={star9 === 1 ? "bgw" : "bgw1"} />
                            <div className={star10 === 1 ? "bgw" : "bgw1"} />
                            <div className={star11 === 1 ? "bgw" : "bgw1"} />
                            <div className={star11 === 1 ? "bgw" : "bgw1"} />
                          </div>
                          <div className="d-flex mb-2">
                            <div className={star9 === 1 ? "bgw" : "bgw1"} />
                            <div className={star9 === 1 ? "bgw" : "bgw1"} />
                            <div className={star10 === 1 ? "bgw" : "bgw1"} />
                            <div className={star11 === 1 ? "bgw" : "bgw1"} />
                          </div>
                        </div>
                        <div className="mt-10">
                          <Link
                            to="/Dashboard/X3"
                            className="btn btn-primary waves-effect waves-light pre btn-sm"
                          >
                            Preview <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row">
              <div className="col-xl-6">
                <div className="widthhhVal">
                  <div className="card">
                    <div className="card-body mt-4">
                      <h4 className="card-title mb-2 colow">
                        Members received
                        {/* <i
                          className="fa fa-question-circle fotes tese"
                          aria-hidden="true"
                        /> */}
                      </h4>
                      <h3 className="mb-4 colow">{dashData.MemberReceived} ADA</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="widthhhVal">
                  <div className="card">
                    <div className="card-body mt-4">
                      <h4 className="card-title mb-2 colow">
                        Total Members{" "}
                        {/* <i
                          className="fa fa-question-circle fotes tese"
                          aria-hidden="true"
                        /> */}
                      </h4>
                      <h3 className="mb-4 colow">{dashData?.TotalMember}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row"></div>
          </div>{" "}
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© adazo.io.</div>
              {/*  <div class="col-sm-6">
                          <div class="text-sm-end d-none d-sm-block">
                              Design by adazo.io
                          </div>
                      </div> */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Dashboard;
