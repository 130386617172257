import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { Link } from "react-router-dom";
import usdt from "../../assets/usdt.png";
import { contractAddress, abi, } from "../../connectivity/api";
import { useSelector, useDispatch } from "react-redux";
import { totalrevnew } from "../../Actions";
import { API } from "../../Utils/Api";
import treeicon from "../../assets/treeicon.png";
const X3_Child = ({ slot, _package }) => {
  // console.log("slot: " + slot);
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  let myteam = useSelector((state) => state.totalteamsize);
  let User_MainID = useSelector((state) => state.MainID);

  const [userteam, setuserteam] = useState(0);
  const [renewslot1, setrenewslot1] = useState(0);
  const [treeData, settreeData] = useState();

  const [addresses, setAddresses] = useState({
    id: "",
    parent: "",
    levelOne: [],
    levelTwo: [],
  });

  const userinfo = async () => {
    try {
      console.log("User_MainID", User_MainID, LoginAddress);
      let intex1;
      let totalslotrevenue;
      let a;
      let aa;
      let formattedAddresses = [];
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        let { id } = await contract.methods.users(LoginAddress).call();
        const {
          0: addressZero,
          1: arrayOne,
          2: arrayTwo,
          3: boolThree,
          4: addressFour,
        } = await contract.methods.usersa2Matrix(LoginAddress, slot).call();
        setuserteam(parseInt(arrayOne.length));

        let arrayOneArray = [];
        for (let item of arrayOne) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayOneArray.push(id);
        }

        let arrayTwoArray = [];
        for (let item of arrayTwo) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayTwoArray.push(id);
        }

        let treeid = id === undefined ? "" : parseInt(id);

        setAddresses({
          id: treeid,
          parent:
            addressZero === "0x0000000000000000000000000000000000000000"
              ? ""
              : addressZero,
          levelOne: arrayOneArray,
          levelTwo: arrayTwoArray,
        });

        const { 0: recycleId, 1: recyclePosition_ } = await contract.methods
          .getPosition(LoginAddress, slot)
          .call();
        setrenewslot1(parseInt(recycleId));

        let totalrenew = parseInt(parseInt(parseInt(myteam.teamsize)) / 12);
        dispatch(
          totalrevnew(
            window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether")
          )
        );
      }
    } catch (error) {
      console.log("Tree_View_Child Error :", error);
    }
  };

  useEffect(() => {
    userinfo();
    bindTree_New();
  }, []);



  const bindTree_New = async () => {
    try {
      // let contract = new window.web3.eth.Contract(abi, contractAddress);
      // let { id } = await contract.methods.users(LoginAddress).call();     
      let response = await API.post("/GetUserTreeData_A3_Latest", {
        uid: parseInt(LoginId),
        package: slot,
      });

      response = response.data.data;
      console.log("bindTree_New", response);
      settreeData(response);
    } catch (error) { }
  };


  return (
    <div className="col-lg-12 col-xl-12 pb-2 ">
      <Link to={`/Dashboard/X3Slot${slot}`}>
        <div className=" pb-5">
          <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
            <div className="row">
              <div className="PDev">
                <p>Slot {slot}</p>
                <div>
                  ADA &nbsp;
                  {/* <img src={usdt} alt="usdt" className="usdtCoin" /> */}
                  <b>
                    {_package}
                    {/* <PackageAmount slot={slot} /> */}
                  </b>
                </div>
              </div>
            </div>
            <div className="TreeImgMian ">
              <ul class="tree vertical">
                <li className="kzsxjka">
                  <div class="popover__wrapper">
                    <a>
                      <h2 class="popover__title">
                      <img src={treeicon} alt="usdt" className="usdtCoin" />
                        <div
                          _ngcontent-cgm-c25=""
                          className={
                            treeData !== undefined ? treeData.treeData[0].uid : ""
                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                              : "bg-white-200 h-5 rounded-full w-5"
                          }
                        >
                          {treeData !== undefined ? treeData.treeData[0].uid : ""}
                        </div>
                      </h2>
                    </a>
                  </div>
                  <ul>
                    <li>
                      <div class="popover__wrapper">
                        <a>
                          <h2 class="popover__title">
                          <img src={treeicon} alt="usdt" className="usdtCoin" />
                            <div
                              _ngcontent-cgm-c25=""
                              className={
                                treeData !== undefined ? treeData.treeData[1].uid : ""
                                  ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                  : "bg-white-200 h-5 rounded-full w-5"
                              }
                            >
                              {treeData !== undefined ? treeData.treeData[1].uid : ""}
                            </div>
                          </h2>
                        </a>
                      </div>
                      <ul>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined ? treeData.treeData[3].uid : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined ? treeData.treeData[3].uid : ""}
                                </div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[7].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[7].uid : ""}
                                    </div>
                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li style={{ display: "none" }}>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[14]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[14]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[8].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[8].uid : ""}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li style={{ display: "none" }}>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[5]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {addresses[5]}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[16]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[16]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[17]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[17]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[18]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[18]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined ? treeData.treeData[4].uid : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined ? treeData.treeData[4].uid : ""}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[9].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[9].uid : ""}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li style={{ display: "none" }}>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[20]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[20]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[10].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[10].uid : ""}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li style={{ display: "none" }}>
                      <div class="popover__wrapper">
                        <a>
                          <h2 class="popover__title">
                          <img src={treeicon} alt="usdt" className="usdtCoin" />
                            <div
                              _ngcontent-cgm-c25=""
                              class={
                                addresses[2]
                                  ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                  : "bg-white-200 h-5 rounded-full w-5"
                              }
                            >
                              {addresses[2]}
                            </div>
                          </h2>
                        </a>
                      </div>
                      <ul>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[7]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {addresses[7]}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[22]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[22]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[23]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[23]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[24]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[24]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[8]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {addresses[8]}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[25]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[25]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[26]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[26]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[27]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[27]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[9]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {addresses[9]}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[28]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[28]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[29]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[29]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[30]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[30]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="popover__wrapper">
                        <a>
                          <h2 class="popover__title">
                          <img src={treeicon} alt="usdt" className="usdtCoin" />
                            <div
                              _ngcontent-cgm-c25=""
                              className={
                                treeData !== undefined ? treeData.treeData[2].uid : ""
                                  ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                  : "bg-white-200 h-5 rounded-full w-5"
                              }
                            >
                              {treeData !== undefined ? treeData.treeData[2].uid : ""}
                            </div>
                          </h2>
                        </a>
                      </div>
                      <ul>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined ? treeData.treeData[5].uid : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined ? treeData.treeData[5].uid : ""}
                                </div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[11].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[11].uid : ""}
                                    </div>
                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li style={{ display: "none" }}>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[32]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[32]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[12].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[12].uid : ""}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li style={{ display: "none" }}>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[11]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {addresses[11]}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[34]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[34]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[35]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[35]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[36]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[36]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined ? treeData.treeData[6].uid : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined ? treeData.treeData[6].uid : ""}
                                </div>

                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[13].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[13].uid : ""}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li style={{ display: "none" }}>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                        addresses[38]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {addresses[38]}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                  <img src={treeicon} alt="usdt" className="usdtCoin" />
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        treeData !== undefined ? treeData.treeData[14].uid : ""
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    >
                                      {treeData !== undefined ? treeData.treeData[14].uid : ""}
                                    </div>

                                  </h2>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="PDev">
                <div className="d-flex">
                  <div className="me-4">
                    <p className="mb-0 pb-0">Members</p>
                    <svg
                      _ngcontent-onl-c25=""
                      viewBox="0 0 16 16"
                      fill="none"
                      stroke="#0000"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 stroke-current text-white-500"
                    >
                      <path
                        _ngcontent-onl-c25=""
                        d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <b>{treeData !== undefined
                      ? treeData.cycleMember[0].total_member
                      : 0}</b>
                  </div>
                  <div>
                    <p className="mb-0 pb-0">Cycle</p>
                    <svg
                      _ngcontent-onl-c25=""
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="#2CFF4E"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 stroke-current text-white-500"
                    >
                      <path
                        _ngcontent-onl-c25=""
                        clip-rule="evenodd"
                        d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                      ></path>
                    </svg>
                    <b> {treeData !== undefined
                    ? treeData.cycleMember[0].totalcycle
                    : 0}</b>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default X3_Child;