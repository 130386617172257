import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      sticky="top"
      className={`main-header ${isScrolled ? "scrolled" : ""}`}
    >
      <Container>
        <Navbar.Brand href="#home">
          <div className="header-logo">
            <a href="index.html">
              <img src="assets/images/logo.png" width="80px" />
            </a>
            <a className="main_sticky" href="index.html">
              <img src="assets/images/logo.png" width="80px" alt="" />
            </a>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto resHdr">
            <Nav.Link href="/" className="lenk">
              Home
            </Nav.Link>
            <Nav.Link href="#about" className="lenk">
              About
            </Nav.Link>
            <Nav.Link href="#Services" className="lenk">
              Services
            </Nav.Link>
            <Nav.Link href="#Roadmap" className="lenk">
              Roadmap
            </Nav.Link>
            {/* <Nav.Link href="#blog" className="lenk">
              Blog
            </Nav.Link> */}
            <Nav.Link href="#contact" className="lenk">
              Contact
            </Nav.Link>
            <Nav.Link href="/Login" className="lenk_button lenk lenk_buttonLogin">
              Login
            </Nav.Link>
            <Nav.Link href="/Register" className="lenk_button lenk">
              Register
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
