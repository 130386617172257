import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Link } from "react-router-dom";
import usdt from "../../assets/usdt.png";
import treeicon from "../../assets/treeicon.png";
import { contractAddress, abi, loadWeb3 } from "../../connectivity/api";
import { useSelector, useDispatch } from "react-redux";
import { totalrevnew } from "../../Actions";
import PackageAmount from "./childComponents/PackageAmount";
import { API } from "../../Utils/Api";

function Tree_View_Child({ slot, _package }) {
  // console.log("slot: " + slot);
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress"); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  let myteam = useSelector((state) => state.totalteamsize);
  let User_MainID = useSelector((state) => state.MainID);

  const [userteam, setuserteam] = useState(0);
  const [renewslot1, setrenewslot1] = useState(0);
  const [treeData, settreeData] = useState();

  const [recycleCount, setrecycleCount] = useState(0);

  const [addresses, setAddresses] = useState({
    id: "",
    parent: "",
    levelOne: [],
    levelTwo: [],
  });

  const userinfo = async () => {
    try {
      // console.log("User_MainID", User_MainID, LoginAddress);
      let intex1;
      let totalslotrevenue;
      let a;
      let aa;
      let formattedAddresses = [];
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (LoginAddress) {
        let { id } = await contract.methods.users(LoginAddress).call();
        const {
          0: addressZero,
          1: arrayOne,
          2: arrayTwo,
          3: boolThree,
          4: addressFour,
        } = await contract.methods.usersa2Matrix(LoginAddress, slot).call();
        setuserteam(parseInt(arrayOne.length));

        let arrayOneArray = [];
        for (let item of arrayOne) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayOneArray.push(id);
        }

        let arrayTwoArray = [];
        for (let item of arrayTwo) {
          let idDetails = await contract.methods.users(item).call();
          let id = parseInt(idDetails.id);
          arrayTwoArray.push(id);
        }

        let treeid = id === undefined ? "" : parseInt(id);

        setAddresses({
          id: treeid,
          parent:
            addressZero === "0x0000000000000000000000000000000000000000"
              ? ""
              : addressZero,
          levelOne: arrayOneArray,
          levelTwo: arrayTwoArray,
        });

        const { 0: recycleId, 1: recyclePosition_ } = await contract.methods
          .getPosition(LoginAddress, slot)
          .call();
        setrenewslot1(parseInt(recycleId));

        let totalrenew = parseInt(parseInt(parseInt(myteam.teamsize)) / 12);
        // dispatch(
        //   totalrevnew(
        //     window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether")
        //   )
        // );
      }
    } catch (error) {
      console.log("Tree_View_Child Error :", error);
    }
  };

  useEffect(() => {
    // userinfo();
    bindTree_New();
  }, []);

  const bindTree_New = async () => {
    try {
      // let contract = new window.web3.eth.Contract(abi, contractAddress);
      // let { id } = await contract.methods.users(LoginAddress).call();
      let response = await API.post("/GetUserTreeData_Latest", {
        uid: parseInt(LoginId),
        package: slot,
      });
      //console.log("bindTree_New", response);
      response = response.data.data;
      console.log("bindTree_New", response);
      // console.log("bindTree_New==>", response.treeData);
      // console.log("cycleMember==> ",response.cycleMember[0].total_member);
      // console.log("treePreData ==> ", response.treePreData[0].newuid);
      settreeData(response);
    } catch (error) {
      console.log("Error while in bindTree_New ", error);
    }
  };

  return (
    <div className="col-lg-6 col-xl-4 pb-2 ">
      <Link to={`/Dashboard/Slot${slot}`}>
        <div className=" pb-5">
          <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
            <div className="row">
              <div className="PDev">
                <p>Slot {slot}</p>
                <div>
                  ADA &nbsp;
                  {/* <img src={usdt} alt="usdt" className="usdtCoin" /> */}
                  <b>
                    {_package}
                    {/* <PackageAmount slot={slot} /> */}
                  </b>
                </div>
              </div>
            </div>
            <div className="TreeImgMian ">
              <ul className="tree vertical">
                <li className="kzsxjka">
                  <div className="popover__wrapper">
                    <a>
                      <h2 className="popover__title">
                      <img src={treeicon} alt="usdt" className="usdtCoin" />
                        <div
                          _ngcontent-cgm-c25=""
                          className={
                            treeData !== undefined
                              ? treeData.treeData[0].uid
                              : ""
                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                              : "bg-white-200 h-5 rounded-full w-5"
                          }
                        >
                          {treeData !== undefined
                            ? treeData.treeData[0].uid
                            : ""}
                        </div>
                      </h2>
                    </a>
                  </div>
                  <ul>
                    <li>
                      <div className="popover__wrapper">
                        <a>
                          <h2 className="popover__title">
                          <img src={treeicon} alt="usdt" className="usdtCoin" />
                            <div
                              _ngcontent-cgm-c25=""
                              className={
                                treeData !== undefined
                                  ? treeData.treeData[1].uid
                                  : ""
                                  ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                  : "bg-white-200 h-5 rounded-full w-5"
                              }
                            >
                              {treeData !== undefined
                                ? treeData.treeData[1].uid
                                : ""}
                            </div>
                          </h2>
                        </a>
                      </div>
                      <ul>
                        <li>
                          <div className="popover__wrapper">
                            <a>
                              <h2 className="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined
                                      ? treeData.treeData[3].uid
                                      : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined
                                    ? treeData.treeData[3].uid
                                    : ""}
                                  {treeData !== undefined ? (
                                    treeData.treeData[3].status == 1 ? (
                                      <>
                                        <br></br>
                                        <svg
                                          _ngcontent-onl-c25=""
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          stroke="#2CFF4E"
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="w-5 h-5 stroke-current text-white-500"
                                        >
                                          <path
                                            _ngcontent-onl-c25=""
                                            clip-rule="evenodd"
                                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                                          ></path>
                                        </svg>
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </h2>
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="popover__wrapper">
                            <a>
                              <h2 className="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined
                                      ? treeData.treeData[4].uid
                                      : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined
                                    ? treeData.treeData[4].uid
                                    : ""}
                                  {treeData !== undefined ? (
                                    treeData.treeData[4].status == 1 ? (
                                      <>
                                        <br></br>
                                        <svg
                                          _ngcontent-onl-c25=""
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          stroke="#2CFF4E"
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="w-5 h-5 stroke-current text-white-500"
                                        >
                                          <path
                                            _ngcontent-onl-c25=""
                                            clip-rule="evenodd"
                                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                                          ></path>
                                        </svg>
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </h2>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <div className="popover__wrapper">
                        <a>
                          <h2 className="popover__title">
                          <img src={treeicon} alt="usdt" className="usdtCoin" />
                            <div
                              _ngcontent-cgm-c25=""
                              className={
                                treeData !== undefined
                                  ? treeData.treeData[2].uid
                                  : ""
                                  ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                  : "bg-white-200 h-5 rounded-full w-5"
                              }
                            >
                              {treeData !== undefined
                                ? treeData.treeData[2].uid
                                : ""}
                            </div>
                          </h2>
                        </a>
                      </div>
                      <ul>
                        <li>
                          <div className="popover__wrapper">
                            <a>
                              <h2 className="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined
                                      ? treeData.treeData[5].uid
                                      : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined
                                    ? treeData.treeData[5].uid
                                    : ""}
                                  {treeData !== undefined ? (
                                    treeData.treeData[5].status == 1 ? (
                                      <>
                                        <br></br>
                                        <svg
                                          _ngcontent-onl-c25=""
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          stroke="#2CFF4E"
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="w-5 h-5 stroke-current text-white-500"
                                        >
                                          <path
                                            _ngcontent-onl-c25=""
                                            clip-rule="evenodd"
                                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                                          ></path>
                                        </svg>
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </h2>
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="popover__wrapper">
                            <a>
                              <h2 className="popover__title">
                              <img src={treeicon} alt="usdt" className="usdtCoin" />
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    treeData !== undefined
                                      ? treeData.treeData[6].uid
                                      : ""
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                >
                                  {treeData !== undefined
                                    ? treeData.treeData[6].uid
                                    : ""}
                                  {treeData !== undefined ? (
                                    treeData.treeData[6].status == 1 ? (
                                      <>
                                        <br></br>
                                        <svg
                                          _ngcontent-onl-c25=""
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          stroke="#2CFF4E"
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="w-5 h-5 stroke-current text-white-500"
                                        >
                                          <path
                                            _ngcontent-onl-c25=""
                                            clip-rule="evenodd"
                                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                                          ></path>
                                        </svg>
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </h2>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="PDev">
                <div>
                  <svg
                    _ngcontent-onl-c25=""
                    viewBox="0 0 16 16"
                    fill="none"
                    stroke="#0000"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 stroke-current text-white-500"
                  >
                    <path
                      _ngcontent-onl-c25=""
                      d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <b>
                    {treeData !== undefined
                      ? treeData.cycleMember[0].total_member
                      : 0}
                  </b>
                </div>
                <div>
                  {treeData !== undefined
                    ? treeData.cycleMember[0].totalcycle
                    : 0}
                  <svg
                    _ngcontent-onl-c25=""
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="#2CFF4E"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 stroke-current text-white-500"
                  >
                    <path
                      _ngcontent-onl-c25=""
                      clip-rule="evenodd"
                      d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Tree_View_Child;
