import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Modal from "./Modal";

const Hero = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Show the modal on initial render
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <>
        <Header />
        {/* <Modal show={showModal} onClose={handleCloseModal} /> */}
        {/* Start -slider-area */}
        {/* ============================================================= */}
        <div className="slider-area d-flex align-items-center" id="home">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="single-slider-box">
                  <div className="slider-content">
                    <div className="slider-title">
                      <h2 className="h2">
                        "Unlock the <span className="co">ADAZO</span> advantage:
                        Simple, Safe, and Exceptional”
                      </h2>
                      {/* <h1>RISING FROM THE ASHES </h1> */}
                      <p>
                        A decentralized networking platform based on smart
                        contracts, together with NFT technology, which brings
                        people together from all over the world andopens up
                        endless possibilities new economic financial systems
                      </p>
                    </div>
                  </div>
                  {/* <div className="slider-button">
                    <a href="#">Get Started Now</a>
                  </div>

                  <div className="slider-button mt-3">
                    <a href="./PHOENIX_MATRIXES.pdf" target="_blank">
                      Presentation
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-sliders-box">
                  <div className="slider-thumb bounce-animate">
                    <center>
                      <img src="assets/images/1.png" width="90%" />
                    </center>
                    <div class="hero-shape ">
                      <center>
                        <img
                          src="assets/images/2.png"
                          className="box"
                          alt=""
                          width="100%"
                        />
                      </center>
                    </div>
                    {/* <div class="shaps_img rotateme">
							<img src="assets/images/crypto.png" alt="">
						</div>
						<div class="seps_img bounce-animate">
							<img src="assets/images/crypto-1.png" alt="">
						</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div className="data-table backre">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <div class="sub-title text-center">
                    <h3>market</h3>
                  </div>
                  <div className="main-title text-center">
                    <h1>Advantage of Adazo Consortium Matrix</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <ul style={{ listStyle: "none", color: "white" }}>
                    <li>1. Advanced Smart Contract with Unique Features.</li>
                    <li>2. Innovative Business Model.</li>
                    <li>
                      3. Unique Matrix plan benefitting every participant.
                    </li>
                    <li>
                      4. Strong leadership is rewarded with enhanced
                      Compensation.
                    </li>
                    <li>
                      5. Created by Consortium of Minds with years of Experience
                      behind the Best Smart Contracts
                    </li>
                    <li>
                      6. Eliminating problems found in most existing matrix
                      plans worldwide.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start about-area */}
        {/*==================================================*/}
        <div className="about-area backe" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title mt-50">
                  <div class="sub-title text-center">
                    <h3>About</h3>
                  </div>
                  <div className="main-title text-center">
                    <h1>Why ADA Cardano</h1>
                    <p className="text-white">
                      ADA, one of the most promising coins for the future, is
                      backed<br></br> by Cardano, known as one of the most
                      robust and powerful<br></br> blockchains ever created.
                      This makes it the top choice for<br></br> crowdfunding
                      programs, offering scalability, sustainability,<br></br>{" "}
                      and high security.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-thumb1">
                    <center>
                      <img src="assets/images/1.png" alt="" width="60%" />
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <br></br>
                  <div className="about-single-icon-box d-flex">
                    <div className="about-icon-thumb">
                      <img src="assets/images/icons.png" alt="" />
                    </div>
                    <div className="about-content ms-4 pt-2">
                      <h3 className="text-white">Scalibility</h3>
                      <p className="text-white">
                        ADA Cardano scales efficiently to handle high
                        transaction volumes
                      </p>
                    </div>
                  </div>
                  {/* <br></br>
                <div className="about-single-icon-box d-flex">
                  <div className="about-icon-thumb">
                    <img src="assets/images/icons1.png" alt="" />
                  </div>
                  <div className="about-content ms-4 pt-2">
                    <h3 className="text-white">Scalibility</h3>
                    <p className="text-white">ADA Cardano scales efficiently to handle high transaction volumes</p>
                  </div>
                </div> */}
                  <br></br>

                  <div className="about-single-icon-box d-flex">
                    <div className="about-icon-thumb">
                      <img src="assets/images/icons2.png" alt="" />
                    </div>
                    <div className="about-content ms-4 pt-2">
                      <h3 className="text-white">Interoperability</h3>
                      <p className="text-white">
                        ADA Cardano is very nominal price of transaction fee and
                        easy to transfer
                      </p>
                    </div>
                  </div>

                  {/* <div className="section-title mt-4">
                  
                    <div className="main-title">
                      <h1>
                        What is{" "}
                        <span className="text-gradient"> Smart Contract </span>
                      </h1>
                    </div>
                    <div className="section-text">
                      <p>
                        Technology of smart contracts and non-fungible tokens
                        Decentralized marketing is powered by the revolutionary
                        technology of smart contracts and NFTs. The phoenix
                        smart contract code is completely open. You can be sure
                        of its safety and long-term performance
                      </p>
                    </div>
                  </div>
                  <div className="slider-button mt-4">
                    <a href="#">Start Trade</a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area backk" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <div className="main-title text-center">
                    <h1>Why Choose ADAZO</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-10 justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="single-token-box">
                  <div className="token-thumb">
                    <h3>100% Decentralized</h3>
                  </div>
                  <div className="token-content">
                    {/*<div class="token-btn">
                              <a href="#">4.5</a>
                            </div>*/}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="single-token-box">
                  <div className="token-thumb">
                    <h3>
                      100% <br></br>Trusted
                    </h3>
                  </div>
                  <div className="token-content">
                    {/*<div class="token-btn">
                              <a href="#">2.5</a>
                            </div>*/}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-token-box">
                  <div className="token-thumb">
                    <h3>100% Distribution</h3>
                  </div>
                  <div className="token-content">
                    {/*<div class="token-btn">
                              <a href="#">4.5</a>
                            </div>*/}
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="section-text">
                    <p className="text-white text-center fs-16">
                      "Adazo distinguishes itself as the premier choice among
                      crowdfunding platforms for several compelling reasons.
                      With a commitment to empowering users through ADA,
                      Cardano's native cryptocurrency, we offer a streamlined
                      and accessible process that prioritizes simplicity without
                      compromising on security or transparency. Our platform is
                      built on blockchain technology, ensuring every transaction
                      is securely recorded and verifiable. Beyond mere
                      functionality, Adazo fosters a vibrant community where
                      members actively engage and support each other's
                      endeavors. Our innovative reward structure, including the
                      Consortium Matrix, Direct Income, Share Dividend, and Rank
                      Achievement Reward, provides multiple avenues for users to
                      earn rewards and grow their investments. Above all, Adazo
                      stands as a beacon of opportunity, dedicated to helping
                      individuals achieve financial independence and realize
                      their aspirations with confidence and integrity."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area backre mt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-thumb1">
                    <img src="assets/images/sin.png" alt="" width="90%" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="section-title mt-4">
                    <div class="sub-title">
                      <h3>Why ADAZO is Special</h3>
                    </div>
                    <div className="main-title">
                      <h1>Empowering Financial Independence</h1>
                    </div>
                    <div className="section-text">
                      <p>
                        "In the 21st century, as financial crises peak and
                        individuals struggle to provide for their families'
                        basic needs, the importance of money cannot be
                        overstated. Whether it's purchasing food, clothing,
                        transportation, or securing education for our children,
                        financial resources are essential. In these challenging
                        times, ADAZO emerges as a beacon of hope for humanity,
                        offering a solution to financial struggles.
                        <br></br>
                        <br />
                        We believe that ADAZO presents a comprehensive solution
                        for fulfilling individuals' financial needs and
                        empowering them to achieve financial independence. Our
                        platform aims to address the pressing financial
                        challenges faced by many, providing opportunities for
                        individuals to secure their financial futures and lead
                        lives of autonomy and prosperity."
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*==================================================*/}
        {/* Start feature-area */}
        {/*==================================================*/}
        <div className="feature-area" id="Service" style={{ display: "none" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sections-title">
                  {/* <div class="sub-title">
						<h3>features</h3>
					</div> */}
                  <div className="main-title">
                    <h1>
                      World Class Trading <br /> Platform
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/1.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Exchange</h3>
                    <p>
                      {" "}
                      Join the Future of Finance. Log in to Your Adazo Matrix
                      Account and Embark on Your Journey of Buying and Selling
                      Crypto currencies Today!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/2.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>High Performance</h3>
                    <p>
                      Seamless Trading." Trade with Confidence and Easy,
                      Supported by Cutting-Edge Software and Exceptional
                      Service.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/3.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Super Fast KYC</h3>
                    <p>
                      Lightning-Fast KYC. Experience Seamless Verification and
                      User-Friendly KYC Processes. Get Trading in Seconds!"{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/4.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Order Types</h3>
                    <p>
                      With a diverse array of order types at your fingertips,
                      tailored to your every trading need, experience the
                      ultimate freedom in crypto asset transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/5.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Minimal Interface</h3>
                    <p>
                      Where Minimalism Meets Functionality. Our platform boasts
                      a sleek and minimal interface, designed for maximum user
                      efficiency{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/6.svg" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Safe And Secure</h3>
                    <p>
                      We prioritize your security above all else. Our platform
                      employs top-notch security measures, guaranteeing the
                      safety of your transactions.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div className="feature-area backre" id="Roadmap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sections-title">
                  {/* <div class="sub-title">
                  <h3>features</h3>
              </div> */}
                  <div className="main-title">
                    <h1>Make Most of ADAZO</h1>
                    <p className="text-white">
                      Introducing 4 seamless Income Streams of ADAZO
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/dolar.png" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Consortium Matrix</h3>
                    <p>
                      The Consortium Matrix comprises two matrices, A2 and A3,
                      strategically designed to pool project resources and
                      distribute them according to instructions outlined in the
                      smart contract.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/dolar2.png" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Direct Income</h3>
                    <p>
                      Users receive a generous 20% commission based on the
                      amount with which the new members join ADAZO. It's a
                      rewarding opportunity designed to incentivize user
                      engagement and foster growth within the ADAZO community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/dolar3.png" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Share Dividend</h3>
                    <p>
                      Users are eligible to receive Share Dividend with ADAZO
                      upon upgrading their A2 Matrix slot, referring others to
                      join the platform, and achieving Star rank. This unique
                      feature ensures that users are rewarded for their active
                      participation and contribution to the ADAZO community.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="single-feature-box hvr-radial-out">
                  <div className="feature-thumb">
                    <img src="assets/images/dolar4.png" alt="" width="100%" />
                  </div>
                  <div className="feature-title">
                    <h3>Rank Achievement Reward</h3>
                    <p>
                      Whenever a user successfully achieves a predefined
                      business target, they become eligible to receive
                      additional share dividends. This incentivized structure
                      rewards users for their commitment to meeting business
                      objectives, fostering motivation and driving collective
                      success within the ADAZO Community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        <div
          className="testimonial-area"
          id="Roadmap"
          style={{ display: "none" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sections-title">
                  {/* <div class="sub-title">
						<h3>testimonial</h3>
					</div> */}
                  <div className="main-title">
                    <h1>Roadmap</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="owl-carousel testi_list">
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-8.png" alt="" />
                      <div className="testi-title">
                        <h2>alex john</h2>
                        <h4>founder</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(4.5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-9.png" alt="" />
                      <div className="testi-title">
                        <h2>Anna Asler</h2>
                        <h4>Investor</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-7.png" alt="" />
                      <div className="testi-title">
                        <h2>Debra Hiles</h2>
                        <h4>Students</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(4.5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-8.png" alt="" />
                      <div className="testi-title">
                        <h2>alex john</h2>
                        <h4>founder</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(4.5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-testimonial-box">
                    <div className="testimonial-thumb">
                      <img src="assets/images/crypto-9.png" alt="" />
                      <div className="testi-title">
                        <h2>Anna Asler</h2>
                        <h4>Investor</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        Holisticly recaptiualiz collaborative deliverables
                        rather than interactive opportunities. Continually myoca
                        web-enabled done.
                      </p>
                    </div>
                    <div className="testimonial-icon">
                      <ul>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" /> <span>(5)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}

        {/*==================================================*/}
        <div className="call-do-action-area dd_sec tbf_section backre">
          <div className="container">
            <div className="call-do-action-title text-center section-text ">
              <h1 className="mb-2 text-white">How it works</h1>
              <p className="text-white">
                Stacks is a production-ready library of stackable
                <br />
                content blocks built in React Native.
              </p>
            </div>
          </div>
          <div className="dd_cards">
            <div className="row">
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                  <div className="thumb d-flex justify-content-center">
                    <img
                      src="assets/images/how.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span>Step 1</span>
                  <h4 className="">Login &amp; Register</h4>
                  <p>Enter your email address and create a strong password.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                  <div className="thumb d-flex justify-content-center">
                    <img
                      src="assets/images/how_2.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span>Step 2</span>
                  <h4>Complete KYC</h4>
                  <p>
                    Complete the two-factor authentication process (2FA). Wait
                    for your account to be verified and approved .
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar">
                  <div className="thumb d-flex justify-content-center">
                    <img
                      src="assets/images/how_3.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span>Step 3</span>
                  <h4>Start Trading</h4>
                  <p>
                    {" "}
                    Once approved, login to your account and start trading.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-area backre" id="contact">
          <div className="community_sec">
            <div className="container">
              <div className="card bgsw">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="section-title1 mb-0 text-start pb-0 no-border">
                        <h1 className="text-white">Join Our Community </h1>
                        <p className=" mb-0 bt text-white">
                          Adazo Matrix is global. Join the conversation in any
                          of our worldwide communities.
                        </p>
                        <div className="d-flex  joc_social_row ">
                          <a
                            className="joc_social"
                            title=" Facebook"
                            target="_blank"
                            href="#"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a
                            className="joc_social"
                            title=" Twitter"
                            target="_blank"
                            href="#"
                          >
                            <i className="fab fa-twitter  ri-xl" />
                          </a>
                          <a
                            className="joc_social"
                            title=" Instagram"
                            target="_blank"
                            href="#"
                          >
                            <i className="fab fa-instagram  ri-xl" />
                          </a>
                          <a
                            className="joc_social"
                            title="Telegram"
                            target="_blank"
                            href="#"
                          >
                            <i className="fab fa-telegram ri-xl" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <img
                        src="assets/images/joc_img.svg"
                        alt=""
                        className="img-fluid joc_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        {/* Start footer-area */}
        {/*==================================================*/}
        <div className="footer-area">
          <div className="container">
            <div className="row tops">
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-logo">
                    <img src="assets/images/logo.png" width="150px" alt="" />
                  </div>
                  <div className="footer-content">
                    {/* <div class="footer-title">
							<p>Cryptocurrencies are used prim outside existing banking govern institutions hanged</p>
						</div> */}
                    <div className="footer-icon">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-content">
                    <div className="footer-title">
                      <h2>Catagories</h2>
                    </div>
                    <div className="footer-ico">
                      <ul>
                        <li>
                          <a href="index.html">
                            <span>Home</span>
                          </a>
                        </li>
                        <li>
                          <a href="#about">
                            <span>About</span>
                          </a>
                        </li>
                        <li>
                          <a href="#Service">
                            <span>Service</span>
                          </a>
                        </li>
                        <li>
                          <a href="#roadmap">
                            <span>Roadmap</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-content">
                    <div className="footer-title">
                      <h2>Useful Links</h2>
                    </div>
                    <div className="footer-ico">
                      <ul>
                        {/* <li>
                          <a href="#blog">
                            <span>Blog</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="#contact">
                            <span>Contact</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Login</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Register</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-footer-box">
                  <div className="footer-content">
                    <div className="footer-titles">
                      <h2>Newsletter</h2>
                      <p>
                        Get now free 20% discount for all products on your first
                        order
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form_box style-two">
                          <input
                            type="text"
                            name="youe email address"
                            placeholder="Enter E-mail"
                            className="text-white"
                          />
                          <button type="submit">
                            <i className="far fa-paper-plane" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row footer-bottom">
              <div className="col-lg-6 col-md-6">
                <div className="copy-left-box">
                  <div className="copy-left-title">
                    <h3>
                      © Cryptobit all Rights Reserved. By <span>adazo.io</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="copy-right-box">
                  <div className="copy-right-title">
                    <ul>
                      <li>
                        <a href="#">
                          <span>Privacy Policy</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>Terms &amp; Condition</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*==================================================*/}
        {/* Start Search Popup Area */}
        {/*==================================================*/}
        <div className="search-popup">
          <button className="close-search style-two">
            <i className="fa fa-times" />
          </button>
          <button className="close-search">
            <i className="fas fa-arrow-up" />
          </button>
          <form method="post" action="#">
            <div className="form-group">
              <input
                type="search"
                name="search-field"
                defaultValue=""
                placeholder="Search Here"
                required=""
              />
              <button type="submit">
                <i className="fa fa-search" />
              </button>
            </div>
          </form>
        </div>
        {/* scroll strat============  */}
        <div className="scroll-area">
          <div className="top-wrap">
            <div className="go-top-btn-wraper">
              <div className="go-top go-top-button">
                <i className="fa fa-angle-double-up" aria-hidden="true" />
                <i className="fa fa-angle-double-up" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
        {/* scroll end============  */}
      </>
    </div>
  );
};

export default Hero;
