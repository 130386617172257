import React, { useState, useEffect } from "react";
import {
  contractAddress,
  abi,
  X3contract,
  X3abi,
} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { API } from "../../Utils/Api";
import axios from "axios";

const MyShareReport = () => {
  const [Arrdata, setArrdata] = useState([])

  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId =
    sessionStorage.getItem("UserId").toString() != null
      ? parseInt(sessionStorage.getItem("UserId").toString())
      : 0; //myState.address
  const [userid, setuserid] = useState(LoginId);
 
  const referral_API = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      setArrdata([]);
      let shareData = [];
      for (let i = 1; i <= 12; i++) {
        const resultShare1 = await contract.methods
          .usersShare(LoginAddress, i)
          .call();
        let share = parseInt(resultShare1[1]);
        shareData.push(share);
      }
      setArrdata(shareData);
       //console.log("shareData", shareData);
    //   console.log("Arrdata",Arrdata);
    } catch (error) {}
  };

  useEffect(() => {
    referral_API();
  }, [userid]);

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card partnrPdd">
                <div className="card-body">
                  <h4 className="card-title colow mb-4">My Share Report</h4>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th
                            className="align-middle"
                            style={{ background: "#5b0cd1", color: "white" }}
                          >
                            Share Pool
                          </th>
                          <th
                            className="align-middle"
                            style={{ background: "#5b0cd1", color: "white" }}
                          >
                            My Share
                          </th>
                          <th
                            className="align-middle"
                            style={{ background: "#5b0cd1", color: "white" }}
                          >
                            Capping
                          </th>
                          <th
                            className="align-middle"
                            style={{ background: "#5b0cd1", color: "white" }}
                          >
                            Package
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {Arrdata &&
                          Arrdata.map((item, i) => (
                            
                            <tr>
                              <td style={{ color: "white" }}>{i+1}</td>
                              <td style={{ color: "white" }}>{item} </td>                              
                              <td style={{ color: "white" }}>{item == 0 ? 0 : item == 1 ? 100 :  (50*item)+50} </td>                              
                              <td style={{ color: "white" }}>{i+1}</td>
                              
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* end table-responsive */}
                  {/* <div className="pagination-buttons" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <button className="colow" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Previous
                    </button>
                    <button className="colow" onClick={handleNextPage} disabled={indexOfLastRow >= data.length}>
                      Next
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <footer className="footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">© adazo.io.</div>
                  {/*  <div class="col-sm-6">
                          <div class="text-sm-end d-none d-sm-block">
                              Design by adazo.io
                          </div>
                      </div> */}
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyShareReport;
