import React, { useState, useEffect } from "react";
import { contractAddress, abi ,X3contract,X3abi} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";

const Stats = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0; //myState.address
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;

  const contractInfo = async () => {
    try {
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let contract33 = new window.web3.eth.Contract(X3abi, X3contract);
      const response = await contract.methods.showDataContract().call();
      const response33 = await contract33.methods.showDataContract().call();

      let mergedArray = response.concat(response33);
      setData(mergedArray); // Update state with fetched data
    } catch (error) {
      console.log("Error fetching contract data:", error);
    }
  };

  useEffect(() => {
    contractInfo();
  }, [LoginAddress]);

  const formatAmount = (amount) => {
    let d = window.web3.utils.fromWei(amount.toString(), 'ether'); // Convert Wei to Ether
    if(d == 5)
      {
        d = 4.5
      }
      if(d == 10)
        {
          d = 2.25
        }   
        if(d == 20)
          {
            d = 4.5
          }   
          if(d == 30)
            {
              d = 9
            }  
            // if(d == 30)
            //   {
            //     d = 9
            //   } 
            //   if(d == 30)
            //     {
            //       d = 9
            //     }                                             
      return d;
  };

  const formatTime = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000); // Convert BigInt to number and then to milliseconds
    return date.toLocaleString();
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card partnrPdd">
              <div className="card-body">
                <h4 className="card-title colow mb-4">Stats</h4>
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle">S.No</th>
                        <th className="align-middle">Amount</th>
                        <th className="align-middle">Time</th>
                        <th className="align-middle">Slot</th>
                        <th className="align-middle">Upline ID</th>
                        <th className="align-middle">Matrix ID</th>
                        <th className="align-middle">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentRows.length > 0 ? (
                        currentRows.map((item, index) => (
                          <tr key={indexOfFirstRow + index}>
                            <td>{indexOfFirstRow + index + 1}</td>
                            <td>{formatAmount(item.amount)}</td>
                            <td>{formatTime(item.time)}</td>
                            <td>{item.slot.toString()}</td>
                            <td>{item.uplineID.toString()}</td>
                            <td>{item.userId.toString()}</td>
                            <td>{item.add}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="colow">Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* end table-responsive */}
                <div className="pagination-buttons" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                  <button className="colow" onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  <button className="colow" onClick={handleNextPage} disabled={indexOfLastRow >= data.length}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© adazo.io.</div>
              {/*  <div class="col-sm-6">
                          <div class="text-sm-end d-none d-sm-block">
                              Design by adazo.io
                          </div>
                      </div> */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Stats;
